// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
// import "bootstrap"

// Swiper Code
document.addEventListener("DOMContentLoaded", function() {
  const swiper = new Swiper(".swiper-container", {
    // cssMode: true,
    // spaceBetween: 50,
    // nested: true,
    loopedSlides: 8,
    loop: true,
    slidesPerView: "auto",
    // oneWayMovement: "false",
    freeMode: true,
    mousewheel: {
      releaseOnEdges: true,
    },
  });

  // var thumb = document.querySelectorAll(".thumbContainer");

  // thumb.forEach(function(image, index) {
  //   var delay = index * 90;
  //   image.classList.add("fadeInSlide");
  //   image.style.animationDelay = delay + "ms";
  // });
});
